import { Autoplay, Controller, Navigation, Pagination } from "swiper/modules";
/**
 * This class is for basic swiper elements that don't require any overly custom logic/functions
 */
class GenericSwiper {
    constructor(element) {
        this.element = element;
        this.swiperElement = this.element.querySelector(".swiper");
        this.args = this.formatArgs();
        this.init();
    }
    formatArgs() {
        var _a, _b;
        const attributes = JSON.parse((_b = (_a = this.element.dataset) === null || _a === void 0 ? void 0 : _a.args) !== null && _b !== void 0 ? _b : "{}");
        attributes.modules = [];
        if (attributes.navigation) {
            if (typeof attributes.navigation !== "boolean") {
                attributes.navigation.nextEl = this.element.querySelector(`${attributes.navigation.nextEl}`);
                attributes.navigation.prevEl = this.element.querySelector(`${attributes.navigation.prevEl}`);
            }
            attributes.modules.push(Navigation);
        }
        if (attributes.controller) {
            attributes.modules.push(Controller);
        }
        if (attributes.pagination) {
            if (typeof attributes.pagination !== "boolean") {
                attributes.pagination.el = this.element.querySelector(`${attributes.pagination.el}`);
                if (attributes.pagination.clickable) {
                    attributes.pagination.clickable = Boolean(attributes.pagination.clickable);
                }
            }
            attributes.modules.push(Pagination);
        }
        if (attributes.autoplay) {
            attributes.modules.push(Autoplay);
        }
        return attributes;
    }
}
export default GenericSwiper;
