import Swiper from "swiper";
import GenericSwiper from "./GenericSwiper";
/**
 * This class is for basic swiper elements that don't require any overly custom logic/functions
 */
class FeaturedNewsSwiper extends GenericSwiper {
    init() {
        var _a;
        this.args.modules = [...(_a = this.args.modules) !== null && _a !== void 0 ? _a : []];
        // console.log("[FeaturedNewsSwiper.ts] this.args: ", this.args);
        if (this.swiperElement) {
            this.swiper = new Swiper(this.swiperElement, this.args);
        }
    }
}
export default FeaturedNewsSwiper;
