import Swiper from "swiper";
import GenericSwiper from "./GenericSwiper";
/**
 * This class is for basic swiper elements that don't require any overly custom logic/functions
 */
class SimpleSwiper extends GenericSwiper {
    constructor() {
        super(...arguments);
        this.isViewportMobile = false;
    }
    calculateIsMobile() {
        this.isViewportMobile = window.innerWidth <= 1200;
    }
    init() {
        // console.log({ swiperEl: this.swiperElement });
        if (this.swiperElement) {
            if (this.swiperElement.classList.contains("mobile")) {
                this.calculateIsMobile();
                if (this.isViewportMobile) {
                    this.swiper = new Swiper(this.swiperElement, this.args);
                }
                window.addEventListener("resize", () => {
                    this.calculateIsMobile();
                    if (this.isViewportMobile && !this.swiper && this.swiperElement) {
                        this.swiper = new Swiper(this.swiperElement, this.args);
                    }
                    else if (!this.isViewportMobile && this.swiper) {
                        this.swiper.destroy();
                        this.swiper = undefined;
                    }
                });
            }
            else {
                this.swiper = new Swiper(this.swiperElement, this.args);
            }
        }
    }
}
export default SimpleSwiper;
