import ScreenSizes from "../utils/ScreenSizes";
class PageNavigation {
    constructor(element) {
        this.activeMenuItem = null;
        if (!(element instanceof HTMLElement)) {
            throw new TypeError("Invalid HTMLElement");
        }
        this.element = element;
        this.menu = document.querySelector("#site-menu");
        this.menuOpenBtn = document.querySelectorAll(".menu-toggle.open");
        this.menuCloseBtn = document.querySelectorAll(".menu-toggle.close");
        this.initEvents();
    }
    initEvents() {
        this.initMenuItems();
        this.initSubMenuEvents();
        this.initMenuTriggerEvents();
        this.initMenuWidths();
        window.addEventListener("resize", () => {
            this.initMenuWidths();
        });
    }
    initMenuItems() {
        var _a, _b;
        const menuItems = (_b = (_a = this.menu) === null || _a === void 0 ? void 0 : _a.querySelectorAll(".nav > .menu-item")) !== null && _b !== void 0 ? _b : [];
        menuItems.forEach((el) => {
            const setMenuItem = () => {
                if (menuItems.length > 0) {
                    this.setActiveMenuItem(el, menuItems);
                }
            };
            el.addEventListener("mouseenter", setMenuItem, true);
        });
    }
    setActiveMenuItem(el, menuItems) {
        var _a, _b, _c, _d;
        if (window.innerWidth > ScreenSizes.md) {
            (_a = this.activeMenuItem) === null || _a === void 0 ? void 0 : _a.classList.replace("active", "inactive");
            this.activeMenuItem = el;
            // add class "inactive" to all other menu items
            menuItems.forEach((item) => {
                if (item !== el) {
                    item.classList.add("inactive");
                }
            });
            if ((_b = this.activeMenuItem) === null || _b === void 0 ? void 0 : _b.classList.contains("inactive")) {
                (_c = this.activeMenuItem) === null || _c === void 0 ? void 0 : _c.classList.replace("inactive", "active");
            }
            else {
                (_d = this.activeMenuItem) === null || _d === void 0 ? void 0 : _d.classList.add("active");
            }
        }
    }
    initSubMenuEvents() {
        var _a, _b, _c, _d;
        if (window.innerWidth <= ScreenSizes.md && this.menu) {
            const submenus = (_a = this.menu.querySelectorAll(".menu-item.menu-item-has-children")) !== null && _a !== void 0 ? _a : [];
            const subMenuButtons = (_b = this.menu.querySelectorAll(".menu-item-has-children > a")) !== null && _b !== void 0 ? _b : [];
            const backButtons = (_c = this.menu.querySelectorAll(".back-button")) !== null && _c !== void 0 ? _c : [];
            const menuToggles = (_d = this.menu.querySelectorAll(".menu-toggle")) !== null && _d !== void 0 ? _d : [];
            if (submenus.length === 0) {
                return;
            }
            Array.from(subMenuButtons).forEach((el) => {
                el.addEventListener("click", this.toggleSubMenu.bind(this));
            });
            Array.from(backButtons).forEach((el) => {
                el.addEventListener("click", PageNavigation.closeSubMenu.bind(this));
            });
            Array.from(menuToggles).forEach((el) => {
                el.addEventListener("click", this.toggleMenu.bind(this));
            });
        }
    }
    initMenuWidths() {
        var _a, _b;
        if (!this.menu)
            return;
        const menuItems = (_a = this.menu.querySelectorAll(".nav > .menu-item")) !== null && _a !== void 0 ? _a : [];
        const menuItemWidth = PageNavigation.getMenuItemsWidth(Array.from(menuItems));
        const submenus = (_b = this.menu.querySelectorAll(".menu-item .sub-menu")) !== null && _b !== void 0 ? _b : [];
        if (this.menu && window.innerWidth > ScreenSizes.md) {
            PageNavigation.setMenuWidths(Array.from(menuItems), Array.from(submenus), menuItemWidth);
        }
        else if (this.menu) {
            submenus.forEach((el) => {
                el.style.width = "100%";
                el.style.left = "0";
            });
            menuItems.forEach((el) => {
                el.style.width = "100%";
                el.style.left = "0";
            });
        }
    }
    static getMenuItemsWidth(menuItems) {
        let width = 75;
        const offset = 35;
        console.log("menuItems: ", menuItems);
        menuItems.forEach((el) => {
            const anchor = el.querySelector("a");
            if (anchor) {
                console.log("anchor width: ", anchor.getBoundingClientRect().width);
                if (anchor && anchor.offsetWidth > width) {
                    width = anchor.offsetWidth;
                }
            }
        });
        return width + offset;
    }
    static setMenuWidths(menuItems, submenus, menuItemWidth) {
        const offset = 10;
        const menuWidthWithOffset = menuItemWidth + offset;
        menuItems.forEach((el) => {
            el.style.width = `${menuWidthWithOffset}px`;
        });
        submenus.forEach((el) => {
            el.style.width = `calc(100% - ${menuWidthWithOffset}px)`;
            el.style.left = `${menuWidthWithOffset}px`;
        });
    }
    toggleMenu() {
        var _a;
        (_a = this.menu) === null || _a === void 0 ? void 0 : _a.classList.toggle("open");
        // this.closeOtherSubmenus(submenu);
    }
    openSubmenu(submenu) {
        submenu.classList.add("open");
        this.element.classList.add("open");
        // this.closeOtherSubmenus(submenu);
    }
    closeSubmenu(submenu) {
        submenu.classList.remove("open");
        this.element.classList.remove("open");
    }
    static openNav(event) {
        event.preventDefault();
        window.document.documentElement.classList.add("site-menu-open");
    }
    closeNav(event) {
        var _a, _b, _c, _d;
        event.preventDefault();
        window.document.documentElement.classList.remove("site-menu-open");
        // reset menu on close
        const menuItems = (_b = (_a = this.menu) === null || _a === void 0 ? void 0 : _a.querySelectorAll(".nav > .menu-item")) !== null && _b !== void 0 ? _b : [];
        menuItems.forEach((el) => {
            el.classList.remove("active");
            el.classList.remove("inactive");
        });
        const openSubmenus = (_d = (_c = this.menu) === null || _c === void 0 ? void 0 : _c.querySelectorAll(".sub-menu.active")) !== null && _d !== void 0 ? _d : [];
        openSubmenus.forEach((el) => {
            el.classList.remove("active");
        });
    }
    initMenuTriggerEvents() {
        if (this.menuOpenBtn) {
            this.menuOpenBtn.forEach((el) => {
                el.addEventListener("click", PageNavigation.openNav.bind(this));
            });
        }
        if (this.menuCloseBtn) {
            this.menuCloseBtn.forEach((el) => {
                el.addEventListener("click", this.closeNav.bind(this));
            });
        }
    }
    toggleSubMenu(event) {
        event.preventDefault();
        if (event.currentTarget && window.innerWidth < ScreenSizes.md) {
            const target = event.currentTarget;
            const parent = target.parentElement;
            // if the menu is already open then navigate to page else open submenu
            if (parent && parent.classList.contains("active")) {
                event.preventDefault();
            }
            if (parent) {
                const menu = parent.querySelector(".sub-menu");
                if (menu) {
                    menu.classList.toggle("active");
                    parent.classList.toggle("active");
                }
            }
        }
    }
    static closeSubMenu(event) {
        const button = event.currentTarget;
        const menu = button === null || button === void 0 ? void 0 : button.closest(".sub-menu");
        console.log("closing menu: ", menu);
        menu === null || menu === void 0 ? void 0 : menu.classList.remove("active");
    }
}
export default PageNavigation;
