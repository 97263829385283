/**
 * Copy URL To Clipboard, with success toast
 *
 * Modified from: https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript
 */
class CopyUrlToClipboard {
    constructor(element) {
        this.fallbackCopyTextToClipboard = (text) => {
            let success = false;
            let textArea = document.createElement('textarea');
            textArea.value = text;
            // Avoid scrolling to bottom
            textArea.style.top = '0';
            textArea.style.left = '0';
            textArea.style.position = 'fixed';
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            try {
                document.execCommand('copy');
                // let successful = document.execCommand('copy');
                // let msg = successful ? 'successful' : 'unsuccessful';
                // console.log('Fallback: Copying text command was ' + msg);
                // this.createAlert();
                success = true;
            }
            catch (error) {
                console.error('Fallback: Oops, unable to copy', error);
            }
            document.body.removeChild(textArea);
            return success;
        };
        /**
         * FIXME: weird bug causing navigator to fail,
         *  so chose to call fallbackCopyTextToClipboard
         *  issue does not occur on local version
         */
        this.copyTextToClipboard = (text) => {
            if (!navigator.clipboard) {
                return this.fallbackCopyTextToClipboard(text);
            }
            navigator.clipboard.writeText(text).then(function () {
                // console.log('Async: Copying to clipboard was successful!');
                // this.createAlert();
                return true;
            }, function (error) {
                console.error('Async: Could not copy text: ', error);
                return false;
            });
        };
        this.successToast = (currentTarget) => {
            // alert('URL Copied to Clipboard');
            currentTarget.classList.toggle('copied', true);
            setTimeout(function () {
                currentTarget.classList.toggle('copied', false);
            }, 1500);
        };
        this.createAlert = () => {
            alert('URL Copied to Clipboard');
        };
        this.container = element;
        this.container.addEventListener('click', (e) => {
            const btn = e.currentTarget;
            if (btn === null) {
                return;
            }
            const href = btn.dataset.href;
            if (typeof href !== 'string') {
                return;
            }
            if (this.fallbackCopyTextToClipboard(href)) {
                this.successToast(btn);
            }
            else {
                console.error('[CopyLink] copyTextToClipboard() unable to copy link');
            }
        });
    }
}
export default CopyUrlToClipboard;
