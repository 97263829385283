/* globals jQuery, wordpress_globals */
import "../sass/theme.scss";
import $ from "jquery";
import "lazysizes";
import "lazysizes/plugins/parent-fit/ls.parent-fit";
import "lazysizes/plugins/unveilhooks/ls.unveilhooks";
// @ts-ignore
import svgxhr from "webpack-svgstore-plugin/src/helpers/svgxhr";
import PageNavigation from "./modules/PageNavigation";
import CopyUrlToClipboard from "./modules/CopyUrlToClipboard";
import FeaturedNewsSwiper from "./modules/FeaturedNewsSwiper";
import SimpleSwiper from "./modules/SimpleSwiper";
import AnimatedScroll from "./modules/AnimatedScroll";
import ScreenSizes from "./utils/ScreenSizes";
const moduleDefinition = [
    { selector: ".section--scroll-slider", moduleName: "ScrollSlider" },
];
// Prefer creating new modules when appropriate, rather than adding functions here
// If you need something to be globally accessible, you can assign an imported module to this namespace
// I.E import Contact from './contact';
// window.namespace.Contact = Contact;
const bootstrap = {
    init() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
        this.helpers.initSvg();
        new AnimatedScroll();
        ((_a = document.querySelectorAll("#page-navigation")) !== null && _a !== void 0 ? _a : []).forEach((el) => new PageNavigation(el));
        ((_b = document.querySelectorAll(".copy-link")) !== null && _b !== void 0 ? _b : []).forEach((el) => new CopyUrlToClipboard(el));
        Array.from((_c = document.querySelectorAll(".featured-news")) !== null && _c !== void 0 ? _c : []).forEach((el) => new FeaturedNewsSwiper(el));
        Array.from((_d = document.querySelectorAll(".simple-swiper")) !== null && _d !== void 0 ? _d : []).forEach((el) => new SimpleSwiper(el));
        Array.from((_e = document.querySelectorAll(".section--scroll-slider")) !== null && _e !== void 0 ? _e : []).forEach((el) => {
            import("./modules/ScrollSlider").then((ScrollSlider) => ScrollSlider.default.create(el));
        });
        Array.from((_f = document.querySelectorAll(".section--member-map")) !== null && _f !== void 0 ? _f : []).forEach((el) => {
            import("./modules/MemberMap").then((MemberMap) => MemberMap.default.create(el));
        });
        Array.from((_g = document.querySelectorAll(".rollover-image")) !== null && _g !== void 0 ? _g : []).forEach((el) => {
            import("./modules/RolloverImage").then((RolloverImage) => RolloverImage.default.create(el));
        });
        Array.from((_h = document.querySelectorAll(".section--home-header")) !== null && _h !== void 0 ? _h : []).forEach((el) => {
            const mouseButton = el.querySelector(".btn--mouse");
            if (mouseButton) {
                import("./modules/MouseButton").then((MouseButton) => MouseButton.default.create(mouseButton, el));
            }
        });
        Array.from((_j = document.querySelectorAll("#madd-grid")) !== null && _j !== void 0 ? _j : []).forEach((el) => {
            import("./modules/MADDGrid").then((MADDGrid) => MADDGrid.default.create(el));
        });
        Array.from((_k = document.querySelectorAll("#specialist-grid")) !== null && _k !== void 0 ? _k : []).forEach((el) => {
            import("./modules/SpecialistsGrid").then((SpecialistsGrid) => SpecialistsGrid.default.create(el));
        });
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        // not on safari
        Array.from((_l = document.querySelectorAll(".animated-webp img")) !== null && _l !== void 0 ? _l : []).forEach((el) => {
            const initStatIcon = () => {
                import("./modules/StatIcon").then((StatIcon) => StatIcon.default.create(el));
                document.removeEventListener("mousemove", initStatIcon);
                document.removeEventListener("scroll", initStatIcon);
            };
            // init stat icons on mousemove or scroll
            document.addEventListener("mousemove", initStatIcon);
            document.addEventListener("scroll", initStatIcon);
            const getClosestSectionOrHeader = (statEl) => { var _a, _b; return (_b = (_a = statEl.closest(".section")) !== null && _a !== void 0 ? _a : statEl.closest("header")) !== null && _b !== void 0 ? _b : null; };
            const section = getClosestSectionOrHeader(el);
            if (section) {
                const rect = section.getBoundingClientRect();
                const bottomOfScreenInsideElement = rect.top >= 0 && rect.top <= window.innerHeight;
                const topOfScreenInsideElement = rect.bottom >= 0 && rect.bottom <= window.innerHeight;
                const inViewPort = bottomOfScreenInsideElement || topOfScreenInsideElement;
                if (inViewPort) {
                    initStatIcon();
                }
            }
        });
        Array.from((_m = document.querySelectorAll(".section--map .google-map")) !== null && _m !== void 0 ? _m : []).forEach((el) => {
            import("./modules/MapBlockSimple").then((MapBlockSimple) => {
                var _a, _b, _c, _d, _e;
                const lat = (_a = el.dataset.lat) !== null && _a !== void 0 ? _a : null;
                const lng = (_b = el.dataset.lng) !== null && _b !== void 0 ? _b : null;
                const marker = (_c = el.dataset.marker) !== null && _c !== void 0 ? _c : "https://maps.google.com/mapfiles/kml/paddle/blu-circle.png";
                if (lat && lng) {
                    MapBlockSimple.default.create(el, (_d = wordpress_globals.google_maps_api_key) !== null && _d !== void 0 ? _d : "", (_e = wordpress_globals.google_maps_style) !== null && _e !== void 0 ? _e : "", parseFloat(lat), parseFloat(lng), marker);
                }
            });
        });
        Array.from((_o = document.querySelectorAll("video.header__video")) !== null && _o !== void 0 ? _o : []).forEach((el) => {
            import("./modules/VideoHeader").then((VideoHeader) => VideoHeader.default.create(el));
        });
        Array.from((_p = document.querySelectorAll(".section--media-slider")) !== null && _p !== void 0 ? _p : []).forEach((el) => {
            import("./modules/MediaSwiper").then((MediaSwiper) => MediaSwiper.default.create(el));
        });
        Array.from((_q = document.querySelectorAll(".section--parallax-gallery")) !== null && _q !== void 0 ? _q : []).forEach((el) => {
            var _a;
            const galleryEl = (_a = el.querySelector(".parallax-grid")) !== null && _a !== void 0 ? _a : undefined;
            if (galleryEl) {
                if (window.innerWidth > 991) {
                    import("./modules/ParallaxGallery").then((ParallaxGallery) => ParallaxGallery.default.create(el));
                }
                else {
                    import("./modules/ParallaxGallerySwiper").then((ParallaxGallerySwiper) => ParallaxGallerySwiper.default.create(galleryEl));
                }
            }
        });
        const cards = document.querySelectorAll(".section--posts .card--news, .grid--videos .card--news");
        if (cards.length > 0 && window.innerWidth + 1 >= ScreenSizes.md) {
            import("./modules/CalculateCardHeight").then((CalculateCardHeight) => CalculateCardHeight.default.create(cards));
        }
        const teamCardPhone = document.querySelectorAll(".team-card__phone");
        if (teamCardPhone.length > 0) {
            import("./modules/PhoneModal").then((PhoneModal) => {
                teamCardPhone.forEach((el) => PhoneModal.default.create(el));
            });
        }
        // Allow user to interrupt auto-scrolling
        $(document).bind("scroll mousedown wheel keyup", (e) => {
            if ((e.which || e.type === "mousedown" || e.type === "mousewheel") && document.scrollingElement) {
                $(document.scrollingElement).stop();
            }
        });
    },
    helpers: {
        requireAll(r) {
            r.keys().forEach(r);
        },
        initSvg() {
            svgxhr({
                filename: "./wp-content/themes/class/dist/sprite.svg", // URL to fetch
            });
        },
    },
    loader: (el, moduleName) => {
        try {
            return import(`./modules/${moduleName}`).then((mod) => {
                const Module = mod.default;
                return new Module(el);
            });
        }
        catch (error) {
            const err = error;
            // eslint-disable-next-line no-console
            console.warn(err);
        }
        return false;
    },
};
document.addEventListener("DOMContentLoaded", () => {
    bootstrap.init();
});
// SVG Sprite Loader
bootstrap.helpers.requireAll(require.context("../images/icons/", true, /\.svg$/));
